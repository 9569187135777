// System fonts https://systemfontstack.com/
$sans-serif-fonts: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;

$serif-fonts: Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

$monospace-fonts: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;

a {
  text-decoration: none;
  color: #07e;
  &:hover {
    text-decoration: underline;
  }
}


body {
  margin: 0;
  padding: 0;
  height: 100%;
  line-height: 1.2em;
  background: #fff;

  font-family: $sans-serif-fonts;
}

blockquote {
  border-left: 1px solid #bbb;
  text-indent: none;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $serif-fonts;
}

hr {
  border: 0 0 0 1px dotted black;
}

img.left {
  float: left;
  margin-right: 1em;
  border: 1px solid #000;
}

img.right {
  float: right;
  margin-left: 1em;
  border: 1px solid #000;
}

table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 0;
    .odd {
      background-color: #ddd;
    }
    .even {
      background-color: #eee;
    }

    td {
      margin: 0;
      padding: 3px;
      border-left: 1px solid #aaa;
    }
  }
}

#content {
  text-align: justify;
  padding: 1em 1em;

  p {
    margin-left: 0.5em;
  }
  p.noindent {
    text-indent: 0;
  }
}

#footer {
  bottom: 0;
  padding-top: 1.5em;
  width: 100%;
  text-align: center;
}

#header {
  font-size: 3em;
  font-family: $serif-fonts;
  line-height: 1em;
  text-align: center;
  padding: 8px;

  a {
    text-decoration: none;
    color: #000;
    display: block;
  }
}

#pi {
  font-size: 0.6em;
  float: right;
  color: #000;
  text-decoration: none;
  margin-right: 1em;
  padding: 0 1em;
  display: block;
}

#wrap {
  margin: 0 auto;
  max-width: 1280px;
}

@import './syntax_highlighting';

